//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';
import { UserModel } from '@/modules/auth/user-model';
import i18n from '@/vueI18n';
// import firebase from 'firebase/app';
// import 'firebase/firestore'
// import Message from '@/shared/message/message';
const { fields } = UserModel;

export default {
  name: 'app-signin-page',

  data() {
    return {
      rules: {
        email: fields.email.forFormRules(),
        password: fields.password.forFormRules(),
      },
      model: {},
      isPwd: true,
      isConfirmPwd: true,
      confirmPassword:'',
    };
  },

  computed: {
    ...mapGetters({
      loading: 'auth/loading',
      is_screen_xs: 'layout/is_screen_xs',
      is_labtop: 'layout/is_labtop',
    }),
    language(){
      return i18n.locale
    },

    fields() {
      return fields;
    },
  },
  watch: {
    language() {
               document.title =
            this.i18n('app.title') +
            ' | ' +
            this.i18n('auth.signup') 
    },
  },

  methods: {
    ...mapActions({
      doRegisterEmailAndPassword: 'auth/doRegisterEmailAndPassword',
    }),
     i18n(key, args) {
      return this.$t(key, args);
    },
    async doSubmit() {
      // try {

      //   // await this.$refs.form.validate();
      //   const exsist = (await firebase.firestore().collection('user').where('email','==',this.model.email).get()).docs
      //     if(exsist && exsist.length) {
      //       return Message.error(i18n('iam.errors.userAlreadyExists'))
      //     }
      // } catch (error) {
      //   return;
      // }

      await this.doRegisterEmailAndPassword({
          email: this.model.email,
          password: this.model.password,
        },
      );
    },
  },
};
